html{
  min-height: 100%;
}
body {
  margin: 0;
  /* font-family: "Nunito Sans", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Nunito Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
}

/* html,
body,
* {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans","Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
  color: #757575;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  display: flex;
  height: 100%;
  font-family: "Nunito Sans";
}
.Content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.layout {
  display: flex;
  flex-direction: row;
}
.contentSection {
  display: flex;
  flex-direction: column;
}

.white {
  color: white;
}
.SearchConatiner {
  width: 100%;
}
