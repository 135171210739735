.Patient_Create {
  background: snow;
  display: flex;
  height: 100%;
}
.layout {
  display: flex;
  width: 100%;
}

.containesContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fafafa;
}

.patientFormHoldsButtons {
  display: flex;
  flex-wrap: wrap;
}

.patientFormHoldsButtonsss {
  display: flex;
  flex-wrap: wrap;
}

.patientFormHoldsbasicInfo {
  background: white;
  border-radius: 12px;
  justify-content: center;
  align-self: center;
}
.patientAddFormHasSearch {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 32px 0 32px;
}
.searchBox {
  width: 50%;
}
.patientAddFormContained {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  padding: 0 32px 0 32px;
}
.styleddiv {
  width: fit-content;
  display: flex;
  border-bottom: 5px solid #8bc34a;
}
.btn-green {
  border-radius: 12px;
  background-color: #4caf50;
}

.btn-red {
  border-radius: 12px;
  background-color: rgb(248, 1, 1);
}
