.App {
  display: flex;
  height: 100%;
}
.Content {
  width: 100%;
}
.Dashboard {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 32px 0 32px;
}
.card {
  background: #fff;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 13rem;
  margin: 1rem;
  position: relative;
  width: 13rem;
  border-radius: 12px;
  text-decoration: none;
}
.card h5 {
  font-weight: normal !important;
  color: #616161;
  font-size: 16px;
  margin-bottom: 0;
}
.card-1 {
  border-bottom: 5px solid transparent;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card-1:hover {
  border-bottom: 5px solid #8bc34a;
  box-shadow: 0 35px 20px rgba(139, 195, 74, 0.15);
  cursor: pointer;
  transform: translateY(-2px);
}
.CardContainer {
  display: flex;
  flex-wrap: wrap;
}
.linked {
  text-decoration: none;
  color: black;
}
