.Login-panel {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-image: url("./backgroundpg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.login-top-img {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
.login-top-img-content {
  width: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
